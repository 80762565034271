<template>
  <app-container :title="title" :background="tool.getThemeList(theme)">
    <div class="bind_box">
      <div class="bind_content">
        <!-- 卡号 -->
        <div class="card_num">
          <span class="desc">卡号:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请填写卡号"
            v-model="card"
          />
        </div>
        <div class="card_mobile_underline"></div>
        <!-- 手机验证码组件 -->
        <verification-code @emitNum="getNum"></verification-code>
        <div class="card_code_underline"></div>
        <!-- 验证码 -->
        <div class="code_num">
          <span class="desc">验证码:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请输入验证码"
            v-model="code"
            maxlength="6"
          />
        </div>
        <div class="card_num_underline"></div>
        <!-- 昵称 -->
        <div class="real_name">
          <span class="desc">昵称:</span>
          <input
            class="real_name_input"
            type="text"
            style="border: none"
            placeholder="请输入卡片呢称，可不填写"
            v-model="nickname"
            maxlength="10"
          />
        </div>
        <div class="card_code_num_underline"></div>
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="submit_button" @click="submit">
      提交{{ actionList[pageType - 3] }}
    </div>
    <!-- 温馨提示 -->
    <div class="explain">
      <div class="explain_desc">温馨提示:</div>
      <div class="explain_text">1、请输入有效的手机号码</div>
      <div class="explain_text">2、如果手机号码停用将会导致绑定失败</div>
    </div>
  </app-container>
</template>

<script>
import { toRefs, reactive, onMounted, getCurrentInstance ,nextTick } from "vue";
import { setStore,getStore,removeStore } from "@/utils/store";
import { useRoute } from "vue-router";
import { bindMobile, unBindMobile } from "_API_/api.services";
import { isvalidatemobile } from "@/utils/validate";
export default {
  setup() {
    const state = reactive({
      title: "", //头部标题
      theme: "", //主题名称
      card: "", //卡号
      iccid: "", //iccid卡号
      iccids: [], //用户绑定的卡片列表
      cardId: "", //卡ID
      code: "", //验证码
      mobile: "", //手机号码
      nickname: "", //卡片昵称
      testType: 1,
      pageType: "", //绑定类型
      actionList: ["绑定", "解绑"]
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      //获取手机号码
      getNum(msg) {
        state.mobile = msg.mobile;
      },
      //卡片绑定信息
      submit() {
        //提交绑定手机号码
        if (state.pageType == 3) {
          ctx.bindCard();
        }
        //提交解绑手机号码
        if (state.pageType == 4) {
          ctx.unTie();
        }
      },
      //解绑手机号码后的跳转
      routerJump() {
        //存储绑定标识到sessionStorage
        setStore({
          name: "userBind",
          content: {
            isShow: "1",
            nickname: "",
            mobile: ""
          },
          type: "session"
        });
        //删除loginAccessToken和userInfo信息
        store.dispatch("UserLogout");
        //返回查询页面
        router.push({
          name: "card-query"
        });
      },
      //绑定卡片
      bindCard() {
        //手机验证码组件没传手机号码过来时，再次验证手机号
        let result_phone = isvalidatemobile(state.mobile);
        if (result_phone[0]) {
          tool.alert({
            title: "提示",
            msg: "请输入正确的手机号并获取验证码"
          });
          return;
        }
        //验证码校验
        if (!Boolean(state.code)) {
          tool.toast(
            {
              msg: "请输入验证码" + state.code,
              duration: 1500
            },
            () => {}
          );
          return;
        }
        // 添加别称非必填，数字+字母+中文。最多10位
        if (
          state.nickname &&
          !/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(state.nickname)
        ) {
          tool.toast(
            {
              msg: "请输入正确的卡片别称",
              duration: 1500
            },
            () => {}
          );
          return;
        }
        //调用绑定手机号码接口
        bindMobile({
          card: state.iccid || state.card,
          cardId: state.cardId,
          code: state.code,
          mobile: state.mobile,
          nickname: state.nickname,
          testType: 1,
          IDCard: "",
          realName: "",
          cardPic1: "",
          cardPic2: "",
          exchangeCode: ""
        }).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast(
              {
                msg: "绑定成功",
                duration: 1500
              },
              () => {
                //存储绑定标识到sessionStorage
                setStore({
                  name: "userBind",
                  content: {
                    isShow: "2",
                    nickname: state.nickname,
                    mobile: state.mobile
                  },
                  type: "session"
                });
                //存储用户信息
                store.dispatch("SetUserInfo", {
                  iccid: state.card
                });
                //保存loginAccessToken
                store.dispatch("SetLoginToken", state.card);
                //返回个人中心页面
                router.push({
                  name: "personal"
                });
              }
            );
            return;
          }
          if (res.code == 222) {
            tool.toast(
              {
                msg: "身份认证失败，请上传身份证照提交绑定",
                duration: 1500
              },
              () => {}
            );
            return;
          }
          tool.toast(
            {
              msg: res.msg, 
              duration: 1500
            },
            () => {}
          );
        });
      },
      //解绑手机号码
      unTie() {
        tool.confirm(
          {
            title: "解绑卡片",
            msg: `确定解绑卡片：${state.virtualId || state.card}？`
          },
          () => {
            unBindMobile({
              mobile: state.mobile,
              card: state.card,
              code: state.code
            }).then(({ data: res }) => {
              if (res.code == 0) {
                tool.toast(
                  {
                    msg: "解绑成功",
                    duration: 1500
                  },
                  () => {
                    ctx.routerJump();
                  }
                );
                return;
              }
              tool.toast(
                {
                  msg: res.msg,
                  duration: 1500
                },
                () => {}
              );
            });
          }
        );
      },
      //检测路由参数
      judgeRoute() {
        // 接收路由携带参数
        state.pageType = route.query.pageType || 3;
        state.card = route.query.virtualId || route.query.iccid || "";
        state.iccid = route.query.iccid;
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
			// 当提示换卡成功 且 作为新卡使用时 若需要绑定手机号 则 检测路由参数
			if(getStore({ name: "changeCard2" })){
				ctx.judgeRoute();
				removeStore({ name: "changeCard2",type: "session" });
			}else if (Boolean(info.iccid) || Boolean(info.virtualId)) {
        // 接收路由携带参数
        state.pageType = route.query.pageType || 3;
        Object.assign(state, {
          cardId: info.cardId || "", //获取卡id
          card: info.virtualId || info.iccid ||  "", //获取卡号
          iccids: info.iccids //用户绑定的卡片列表
        });
      } else {
        ctx.judgeRoute();
      }
      switch (Number(state.pageType)) {
        case 3:
          state.title = "绑定手机号码";
          break;
        case 4:
          state.title = "解绑手机号码";
          break;
        default:
          break;
      }
    });
    return { ...toRefs(state), ...methods, tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.bind_box {
  width: 690px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
  padding-top: 20px;
  .bind_content {
    width: 630px;
    height: 408px;
    margin: 0 auto;
    .card_num,
    .code_num,
    .real_name {
      height: 88px;
      background: #ffffff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .real_name_input {
        width: 430px;
        margin-left: 40px;
      }
    }
    .card_num,
    .code_num {
      width: 600px;
      justify-content: space-between;
      input {
        width: 430px;
        margin-right: 60px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .code_num {
      width: 600px;
      justify-content: space-between;
      input {
        width: 430px;
        margin-right: 50px;
      }
      .desc {
        padding-left: 4px;
      }
    }
    .real_name {
      width: 630px;
      margin-left: 20px;
      input {
        width: 430px;
        margin-left: 100px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .card_mobile_underline,
    .card_num_underline,
    .card_code_underline,
    .card_code_num_underline {
      height: 1px;
      background: #f2f2f2;
    }
    .card_mobile_underline {
      width: 490px;
      margin: 0 130px;
    }
    .card_num_underline {
      width: 480px;
      margin: 0 140px;
    }
    .card_code_underline {
      width: 426px;
      margin: 0 190px;
    }
    .card_code_num_underline {
      width: 500px;
      margin: 0 126px;
    }
  }
}

.explain {
  width: 690px;
  height: 188px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .explain_desc {
    font: {
      size: 30px;
      weight: 400;
    }
    color: #333333;
  }
  .explain_text {
    width: 690px;
    font: {
      size: 22px;
      weight: 400;
    }
    color: #777777;
  }
}
.submit_button {
  width: 690px;
  height: 84px;
  color: #ffffff;
  @include background_color("background_color1");
  border-radius: 20px;
  margin: 20px auto 20px;
  text-align: center;
  line-height: 84px;
}
</style>
